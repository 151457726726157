.team-section {
  position: relative;
  background: #ffffff;
  z-index: 1;
  .section-title {
    h3 {
      position: relative;
      padding-bottom: 30px;
    }
    p {
      font-size: 15px;
      line-height: 26px;
      color: #777777;
      font-weight: 400;
      padding-bottom: 30px;
    }
  }
  .team-member{
    margin-top: 20px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
    img{
      width: 100%;
    }
    .contents{
      background: $light;
      padding: 20px 10px;
      h4{
        text-transform: uppercase;
        letter-spacing: 1px;
        margin-bottom: 20px;
        color: $black;
        position: relative;
        &:before{
          position: absolute;
          content: '';
          width: 30px;
          height: 1px;
          background: #777777;
          bottom: -8px;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .btn-main{
        margin-top: 30px;
        background: $light;
        text-transform: uppercase;
        color: $primary-color;
        padding: 10px 25px;
        border: 1px solid $primary-color;
        transition: all .2s ease-in;
        &:hover{
          background: $primary-color;
          color: $light;
        }
      }
    }
  }
}

.team-members{
  .team-person{
    margin-top: 30px;
    img{
      border-radius: 5px;
      width: 100%;
    }
    h6{
      margin-top: 20px;
      font-size: 20px;
      text-transform: uppercase;
      color: $black;
    }
    p{
      font-weight: bold;
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 14px;
      font-family: $primary-font;
    }
  }
}