.video-gallery{
  padding: 100px 0;
  .video-gallery-item{
    margin-top: 50px;
    .image-holder{
      position: relative;
      border-radius: 3px;
      overflow: hidden;
      box-shadow: 0px 0px 20px -5px rgba(0, 0, 0, 0.2);
      img{
        width: 100%;
      }
      &:before{
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba($color: #132646, $alpha: .7);
        top: 0;
      }
      a{
        position: absolute;
        display: block;
        width: 80px;
        height: 80px;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%,-50%,0);
        text-align: center;
        z-index: 5;
        border: 5px solid $light;
        border-radius: 50%;
        transition: all .3s ease-in-out;
        i{
          font-size: 25px;
          line-height: 70px;
          color: $light;
        }
      }
    }
    h3{
      text-align: center;
      color: $primary-color;
      margin-top: 15px;
      text-transform: capitalize;
      font-family: $primary-font;
      font-size: 20px;      
    }
  }
}