.styled-pagination {
  padding-top: 50px;
  ul{
    padding-left: 0;
    li {
      position: relative;
      display: inline-block;
      margin-right: 5px;
      a {
        position: relative;
        display: block;
        line-height: 50px;
        font-size: 16px;
        width: 50px;
        height: 50px;
        color: #777777;
        font-weight: 500;
        text-align: center;
        background: #f4f4f4;
        border-radius: 4px;
        transition: all 500ms ease;
        &.prev, &.next {
          font-size: 18px;
        }
        &:hover, &.active {
          color: #ffffff;
          background: $primary-color;
          transition: all 500ms ease;
        }
      }
    }
  }
}